.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-Container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-wrap: wrap;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'sofasanshand-bk';
  src: url('../../fonts/sofasanshand-bk-webfont.woff2') format('woff2'),
    url('../../fonts/sofasanshand-bk-webfont.woff') format('woff');
}

/* body {
  background: #1FB91C;
} */

.logo {
  font-family: 'sofasanshand-bk', Arial, sans-serif;
  font-size: large;
  color: #FFFFFF;
  line-height: 40%;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: green;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-to-top:hover {
  background-color: #9ECE1A;
}

.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about-content {
  font-size: 16px;
  line-height: 1.6;
  margin-top: 20px;
}

.about-content p {
  margin-bottom: 15px;
}

.about-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

/* Footer styles */
.container.text-center {
  margin-top: 20px;
}