/* Slot Machine Layout */
.recipe-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

/* Individual Recipe Items */
.recipe-item {
    width: calc(100% / 7 - 10px);
    min-width: 100px;
    max-width: 200px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.day-of-week {
    text-align: center;
    margin-bottom: 5px;
    width: 100%;
}

/* Placeholder Box */
.grey-box {
    background-color: #ccc;
    width: 100%;
    aspect-ratio: 16 / 9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.grey-box span {
    font-size: 3vw;
    color: darkgrey;
}

/* Recipe Images */
.recipe-image {
    position: relative;
    cursor: pointer;
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
    overflow: hidden;
}

.recipe-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Accepted Recipes */
.recipe-image.accepted img {
    filter: blur(3px);
}

/* Check Icon */
.check-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: green;
    font-size: 24px;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .recipe-item {
        width: calc(100% / 4 - 10px);
    }
}

@media (max-width: 480px) {
    .recipe-item {
        width: calc(100% / 2 - 10px);
    }
}