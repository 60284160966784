@import url(https://fonts.googleapis.com/css?family=Indie+Flower);

.grocery-list-container {
    font-family: "Indie Flower", cursive;
    letter-spacing: 0.1rem;
    background-image: linear-gradient(90deg, #e8b6b6, 2px, rgba(255, 255, 255, 0), 2px, rgba(255, 255, 255, 0)), linear-gradient(0, #d9eaf3, 1px, rgba(255, 255, 255, 0), 2px, rgba(255, 255, 255, 0));
    background-position: 4rem 0;
    background-size: 100% 2.4rem;
    color: #111;
    padding: 2.2rem 0 0 5rem;
    white-space: nowrap;

    counter-reset: listCounter;
}

.grocery-list-container h1 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.grocery-list-container h2 {
    display: inline-block;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.6rem;
    border-bottom: 2px solid #111;
}

.grocery-list-container ul {
    list-style: none;
    padding: 0;
}

.grocery-list-container li {
    margin-bottom: 0.6rem;
}

.grocery-list-container li::before {
    content: counter(listCounter) ". ";
    counter-increment: listCounter;
}